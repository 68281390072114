<template>
	<div class="title">
		
		<div class="title1">
			<div class="ch">
				<!-- <div class="img"> -->
					<img src="../../assets/yuan.png" style="width:8px;height:8px" >
				<!-- </div> -->
				{{ch}}
			</div>	
			<div class="en">
				{{en}}
			</div>
		</div>
	</div>
</template>

<script>
	export default {
		props:{
			ch:String,
			en:String
		},
		data() {
			return {}
		}
	}
</script>

<style lang="less" scoped>
	.title{
		width: 100%;
		// background-color: red;
		display: flex;
		align-items: center;
		justify-content: center;
		margin-bottom: 10px;
		.ch{
			color: #960;
			font-size: 28px;
			display: flex;
			align-items: flex-start;
			img{
				margin-top: 10px;
				margin-right: 5px;
			}
			// .img{
				
			// }
		}
		.en{
			font-size: 14px;
			color: #999;
			text-align: center;
		}
		// .img{
		// 	position: relative;
		// 	width: 8px;
		// 	height: 100%;
		// 	img{
		// 		position: absolute;
		// 		top: 0;
		// 		width: 100%;
		// 		height: 8px;
		// 	}
		// }
	}
</style>
