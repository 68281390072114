<template>
 <div class="menulist" style="margin-top: 10px;">
     <!-- :style="{'padding-left':padding+'%','padding-right':padding+'%'}" -->
      <div class="menu2" >
          <div class="list" @mouseenter="handleEnter(index)" @mouseleave="handleLeave()" @click="handleClick(menu)" v-for="(menu,index) in menuList" :key="menu.path" >
           <div class="item" >
           	 {{menu.name}}
           </div>
		  <div class="img">
            <Heng v-if="menu.path==$route.path || Index==index" />
		  	 <!-- <img v-if="menu.path==$route.path" src="../../assets/index.png" > -->
		  </div>
           </div>         
         </div>    
         <Background :style="{'width':'calc(100% + 20px)','margin-top':'25px','margin-left':'-10px','margin-bottom':'-15px'}" />
    </div> 
       
</template>

<script>
import Heng from '../components/heng.vue'
import Background from '../components/background.vue'
export default {
    props:{
        menuList:Array,
        padding:Number,
		// width:Number,
    },
    data() {
        return {
			// menuPath:''
            Index:-1
        };
    },
    components:{
        Heng,
        Background
    },
    created() {

    },
    mounted() {

    },
    methods: {
		handleClick(menu){
			this.$router.push(menu.path)
		},
        handleEnter(index){
            this.Index=index
        },
        handleLeave(){
            this.Index=-1
        }
    }
};
</script>

<style scoped lang='less'>
.menu2{
    display:flex;
	justify-content: center;	
    .list{
        display:flex; 
	   margin: 0 20px;
		flex-direction: column;
        justify-content: center;    
        cursor:pointer; 
		.item{
            padding: 0 10px;
			text-align: center;
			
		}
		.img{
			height: 0;
		}
    }
}
</style>
