<template>
    <div class="heng">
        <div class="di">

        </div>
        <div class="react">

        </div>
    </div>
</template>

<script>
export default {
    data() {
        return {

        };
    },
    created() {

    },
    mounted() {

    },
    methods: {

    }
};
</script>

<style scoped lang='less'>
.heng{
width:100%;
    position: relative;
    margin-top: 5px;
    height: 3px;
    display: flex;
    justify-content: center;
    align-items: center;
    .di{
        height: 1px;
        width: 100%;
        // position: absolute;
        // left: -10px;
        // width: calc(100% + 20px);
        background-color: #960;
    }
    .react{
        width: 25%;
        height: 100%;
        background-color: #960;
        position: absolute;
        // left: 50%;
        // transform: translateX(-50%);
        
        // transition: ;
    }
}
</style>
